import {
  address,
  contactUtils,
  payBoxUtils,
  payBoxTypes,
  personTypes,
  productDomains,
  statusTypes,
  telephoneTypes,
  paymentTypes,
  payBoxSale,
  saleDomain,
} from '@/mixins'
import { Decimal } from 'decimal.js-light'
import idbInstance from '@/libs/dexie'
import { nanoid } from 'nanoid'
import { roundDecimal } from '@/utils/number-utils'
import axiosInstance from '@/libs/axios'
import discountTypes from '@/mixins/dominio/discount-types'
import { localListAddItem, localListDeleteItem, localListUpdateItem } from '@/store/utils'
import isElectron from 'is-electron'
import _ from 'lodash'
import delegablePermissions from '@/utils/delegable-permissions'
import { getAppVersions } from '@/utils/app-utils'
import {
  hasTefCardActive,
  hasTefPixActive,
  hasTefPixCanceled,
} from '@/store/utils/paybox/paybox-payments'
import moment from 'moment'
import { validateBr } from 'js-brasil'

const payBoxCurrentSaleIdb = idbInstance.table('payBoxCurrentSale')
const payBoxInvoiceNumberControlIdb = idbInstance.table('payBoxInvoiceNumberControl')

const { paymentMethodInterfaceEnum, paymentTypeEnum } = paymentTypes.data()

export const getInitialPayBoxItem = () => ({
  id: null,
  product: null,
  orderAmount: 0,
  amount: 1,
  maxAmount: -1,
  discountType: discountTypes.computed.discountTypesEnum().VALUE,
  inputMethodEnum: null,
  discount: 0,
  observation: '',
  priceTableId: null,
  promotionId: null,
  discountPriceTable: 0,
  unitValue: 0,
  subtotal: 0,
  returnDate: null, // data que comodato/aluguel retornou na loja
  returnDateLimit: null, // data limite do retorno de comodota ou aluguel
  deliveredDate: null,
  createdDate: null,
  createdBy: '',
  returnQuantityDamaged: 0,
  priceInfo: {
    priceWithDiscount: 0,
    valueDiscounted: 0,
    subtotalWithoutDiscount: 0,
  },
  palmBeerCardNumber: null,
  choppGiftCardId: null,
  isDeleted: false,
})

export const getInitialPayBoxReturnedItem = () => ({
  itemReturnedId: null,
  itemId: null,
  product: null,
  kit: null,
  lendingProduct: null,
  amount: 0,
  maxAmount: 0,
  damagedAmount: 0,
  discountType: discountTypes.computed.discountTypesEnum().VALUE,
  unitDiscount: 0,
  unitValue: 0,
  subtotal: 0,
  unitValueCalculated: 0,
  subtotalCalculated: 0,
  returnDate: null,
  returnDateLimit: null,
  willReturn: false,
})

export const getInitialConsignReturnForm = () => ({
  returnedItems: [],
  returnedLendingItems: [],
  value: 0,
  paymentMethod: null,
  observation: '',
})

export const getInitialReceiptForm = () => ({
  id: null,
  paymentMethod: {
    id: null,
    name: '',
    method: '',
  },
  paymentMethodInterface: paymentMethodInterfaceEnum.TEF,
  value: 0,
  canceled: false,
  change: 0,
  nsu: null,
  documentTitular: null,
  cardBrand: null,
})

export const getInitialDeliveryForm = () => ({
  address: address.data().address,
  observation: '',
  addressSelected: 'other',
  deliveryTaxDiscountValue: 0,
  deliveryTax: 0,
  deliveryStatus: null,
})

export const getInitialPalmBeerCardData = () => ({
  customer: { id: null },
})

export const getInitialChoppGiftCardData = () => ({
  operation: null,
  cardNumber: null,
  value: null,
})

const getInitialState = () => ({
  id: null,
  uuid: null,
  barTabConsumption: {
    id: null,
    barTab: {
      id: null,
      number: '',
    },
  },
  grossValue: 0,
  netValue: 0,
  comission: 0,
  comissionFee: null,
  paymentStarted: false,
  items: [],
  invoiceReturn: null,
  echopeClassification: null,
  offline: false,
  priceTable: null,
  itemForm: getInitialPayBoxItem(),
  clientForm: {
    id: null,
    personType: personTypes.computed.personTypeEnum().CONSUMER,
    landLine: '',
    mobilePhone: '',
    email: '',
    site: '',
    observation: '',
    document: '', // represent CNPJ or CPF
    name: '', // represent name or TradingName
    priceTable: {},

    gender: '',
    birthdate: null,

    companyName: '',
    stateRegistrationType: null,
    stateRegistration: '',
    municipalInscription: '',

    address: address.data().address,
    addressList: [],
  },
  paymentForm: {
    discount: 0,
    receipts: [],
    change: 0,
    balance: 0,
  },
  isDelivery: false,
  isConsigned: false,
  consignedMaxReturnValue: 0,
  deliveryForm: getInitialDeliveryForm(),
  returnForm: getInitialConsignReturnForm(),
  returnSale: {
    value: 0,
  },
  palmBeerData: getInitialPalmBeerCardData(),
  choppGiftCardData: getInitialChoppGiftCardData(),
  tempDelegations: [],
  blockedPayBoxActions: [],
  electronicInvoiceRequired: false,
  electronicInvoiceInvoiceNumberGenerated: 0,
  orderChanged: false,
  coldOption: null,
  eventId: null,
})

const appVersions = JSON.stringify(getAppVersions())

export default {
  namespaced: true,

  state: getInitialState(),

  getters: {
    getElectronicInvoiceRequired(state) {
      return state.electronicInvoiceRequired
    },
    hasActiveTefCardPayment(state) {
      return hasTefCardActive(state.paymentForm.receipts)
    },
    hasActiveTefPixPayment(state) {
      return hasTefPixActive(state.paymentForm.receipts)
    },
    hasCanceledTefPixPayment(state) {
      return hasTefPixCanceled(state.paymentForm.receipts)
    },
    getConsignedReturnData(state) {
      return {
        id: state.id,
        netValue: state.netValue,
        grossValue: state.grossValue,
        returnSale: {
          value: state.returnForm.value,
          paymentMethod: state.returnForm.paymentMethod,
          observation: state.returnForm.observation,
        },
        consigned: state.isConsigned,
        deliveryStatus: state.deliveryForm.deliveryStatus,
        consignedMaxReturnValue: state.consignedMaxReturnValue,
        pendingPaymentValue: state.pendingPaymentValue,
      }
    },
    getItems(state) {
      return state.items.filter(i => !i.isDeleted)
    },
    getOnlyItems(state) {
      return state.items.filter(i => !i.isDeleted)
    },
    getDictItemsQuantity(state) {
      const { getSaleProductKey } = payBoxSale.methods
      return state.items.reduce((dict, item) => {
        const { skuId, promotionId, kit, id } = item.product
        const key = getSaleProductKey({ skuId, promotionId, kitId: (kit && id) || 0 })

        return {
          ...dict,
          [key]: item.amount,
        }
      }, {})
    },
    getCartItemsQuantity(state) {
      const totalQuantityItens = state.items.reduce((total, item) => total + (item?.amount ?? 0), 0)

      return totalQuantityItens || 0
    },

    hasItems(state) {
      return state.items.filter(i => !i.isDeleted).length > 0
    },

    hasSaleItems(state) {
      return (
        state.items
          .flatMap(i => (i.product.kit ? i.product.kitItems : [i]))
          .filter(
            i =>
              !i.product.kit &&
              !i.isDeleted &&
              (i.classification === 'Sale' ||
                i.classification === 'Credit' ||
                i.product.classification === 'Sale' ||
                i.product.classification === 'Credit')
          )
          .filter(i => i.amount > 0).length > 0
      )
    },

    hasLendingItems(state) {
      return (
        state.items.filter(i => (i.classification || i.product.classification) === 'Lending')
          .length > 0
      )
    },

    hasRentItems(state) {
      return (
        state.items.filter(i => (i.classification || i.product.classification) === 'Rent')
          .length > 0
      )
    },
    hasCreditItems(state) {
      return (
        state.items.filter(i => (i.classification || i.product.classification) === 'Credit')
          .length > 0
      )
    },
    hasReturnSaleItems(state) {
      return state.returnForm.returnedItems.filter(i => i.amount > 0).length > 0
    },
    hasPalmBeerCustomer(state) {
      return state.palmBeerData.customer?.id
    },
    hasNewPalmBeerOperation(state, getters) {
      return getters.hasCreditItems
    },
    hasColdOptionProducts(state) {
      return state.items?.some(item => item.hasColdOption)
    },

    isOrder(state) {
      return !!state.id && !state.barTabConsumption?.id
    },
    isChoppGiftCardConversion(state) {
      return !!state.choppGiftCardData.id && state.choppGiftCardData?.operation === 'Conversion'
    },
    isBarConsumption(state) {
      return !!state.barTabConsumption?.id
    },

    isConsignedSalePayment(state) {
      const { COMPLETED } = statusTypes.computed.deliveryStatusEnum()
      return (
        state.isConsigned &&
        (state.deliveryForm.deliveryStatus !== COMPLETED || state.pendingPaymentValue > 0)
      )
    },
    isConsignedSaleFinish(state, getters) {
      return state.isConsigned && !getters.isConsignedSalePayment
    },
    currentSaleUuid(state) {
      const { uuid } = state
      return uuid || ''
    },
    saleInProgress(state) {
      return state.items?.length || state.paymentForm?.receipts?.length
    },
    getSaleData(state) {
      return {
        id: state.id,
        offline: state.offline,
        priceTable: state.priceTable,
        isDelivery: state.isDelivery,
        isConsigned: state.isConsigned,
        barTabConsumption: {
          id: state.barTabConsumption?.id || null,
          barTabNumber: state.barTabConsumption?.barTab?.number || '',
        },
      }
    },

    getSubtotalSale(state, getters) {
      return getters.getItems.reduce((total, item) => total + item.subtotal, 0)
    },
    getTotalSale(state, getters) {
      let total =
        getters.getSubtotalSale -
        state.paymentForm.discount -
        (state.deliveryForm.deliveryTaxDiscountValue || 0)
      if (state.isDelivery) {
        total += state.deliveryForm.deliveryTax
      }

      if (state.comission > 0) {
        total += state.comission
      }

      return roundDecimal(total)
    },
    getTotalReceipt(state) {
      const sumReceipts = state.paymentForm.receipts.reduce((total, r) => {
        if (r.canceled) return total
        const changePaid = r.id ? r.change || 0 : 0
        return total + r.value - changePaid
      }, 0)
      return roundDecimal(sumReceipts)
    },

    getItemInfo(state, getters) {
      if (!getters.hasItems) {
        return { name: '', price: 0, stock: 0, productAmount: 0, subtotal: 0 }
      }

      const { product, amount, subtotal, priceInfo, discount, unitValue } = getters.getItems[0]

      return {
        name: product.name || '',
        price: unitValue || product.price || 0,
        priceWithDiscount: priceInfo.priceWithDiscount || 0,
        stock: product.stock,
        hasPromotion: product.promotionQuantityAvailable > 0 || product.promotionQuantityUnlimited,
        promotionQuantityAvailable: product.promotionQuantityAvailable,
        promotionQuantityUnlimited: product.promotionQuantityUnlimited,
        productAmount: amount,
        subtotal,
        imageSquare: product.imageSquare,
        hasDiscount: discount > 0 || product.unitDiscount > 0,
      }
    },
    getSubtotalSaleWithoutDiscountInfo(state, getters) {
      // TODO utilizar método do pay-box-sale
      const result = getters.getItems.reduce(
        (total, item) => total + item.priceInfo.subtotalWithoutDiscount,
        0
      )
      return roundDecimal(result)
    },
    getTotalItemsDiscountInfo(state, getters) {
      // TODO utilizar método do pay-box-sale
      const result = getters.getItems.reduce(
        (total, item) => total + item.priceInfo.valueDiscounted,
        0
      )

      const deliveryDiscount = state.deliveryForm?.deliveryTaxDiscountValue || 0
      return roundDecimal(result + state.paymentForm.discount + deliveryDiscount)
    },
    getCustomerDocument(state) {
      return state.clientForm.document
    },
    getCustomerName(state) {
      return state.clientForm.name
    },
    clientHasValidAddressToInvoice(state) {
      if (
        state.clientForm.address.publicPlace === null ||
        state.clientForm.address.publicPlace === '-' ||
        state.clientForm.address.publicPlace === ''
      ) {
        return false
      }

      if (
        state.clientForm.address.neighborhood === null ||
        state.clientForm.address.neighborhood === '-' ||
        state.clientForm.address.neighborhood === ''
      ) {
        return false
      }

      if (state.clientForm.address.cityCode === null || state.clientForm.address.cityCode === 0) {
        return false
      }

      if (
        state.clientForm.address.city === null ||
        state.clientForm.address.city === '-' ||
        state.clientForm.address.city === ''
      ) {
        return false
      }

      if (
        state.clientForm.address.province === null ||
        state.clientForm.address.province === '-' ||
        state.clientForm.address.province === ''
      ) {
        return false
      }

      if (
        state.clientForm.address.provinceCode === null ||
        state.clientForm.address.provinceCode === 0
      ) {
        return false
      }

      if (state.clientForm.address.zipcode === null) {
        return false
      }

      return true
    },
  },

  mutations: {
    SET_INVOICE_RETURN(state, invoice) {
      state.invoiceReturn = invoice
    },
    SET_ITEMS(state, items) {
      state.items = items || []
    },
    SET_ITEM_FORM_PRODUCT(state, product) {
      state.itemForm.product = product
    },
    SET_DISCOUNT_PAYMENT_FORM(state, value) {
      state.paymentForm.discount = value
    },
    SET_CLIENT_FORM(state, client) {
      state.clientForm = client
    },
    SET_ELECTRONIC_INVOICE_REQUIRED(state, value) {
      state.electronicInvoiceRequired = value
    },
    SET_ITEM_FORM_AMOUNT(state, amount) {
      state.itemForm.amount = amount
    },
    SET_PALM_BEER_DATA(state, data) {
      state.palmBeerData = data
    },
    SET_CHOPP_GIFT_CARD_DATA(state, data) {
      state.choppGiftCardData = data
    },
    SET_ALL_STATE(state, payload) {
      const {
        id,
        barTabConsumption,
        offline,
        coldOption,
        priceTable,
        clientForm,
        itemForm,
        items,
        paymentForm,
        uuid,
        paymentStarted,
        deliveryForm,
        isDelivery,
        isConsigned,
        consignedMaxReturnValue,
        invoiceReturn,
        returnForm,
        grossValue,
        netValue,
        comission,
        comissionFee,
        returnSale,
        palmBeerData,
        tempDelegations,
        blockedPayBoxActions,
        echopeClassification,
        choppGiftCardData,
        pendingPaymentValue,
        electronicInvoiceRequired,
        electronicInvoiceInvoiceNumberGenerated,
      } = getInitialState()

      state.id = payload.id ?? id
      state.barTabConsumption = payload.barTabConsumption ?? barTabConsumption
      state.offline = payload.offline ?? offline
      state.coldOption = payload.coldOption ?? coldOption
      state.priceTable = payload.priceTable ?? priceTable
      state.uuid = payload.uuid ?? uuid
      state.items = payload.items ?? items
      state.itemForm = payload.itemForm ?? itemForm
      state.clientForm = { ...clientForm, ...payload.clientForm }
      state.paymentForm = payload.paymentForm ?? paymentForm
      state.paymentStarted = payload.paymentStarted ?? paymentStarted
      state.deliveryForm = payload.deliveryForm ?? deliveryForm
      state.isDelivery = payload.isDelivery ?? isDelivery
      state.isConsigned = payload.isConsigned ?? isConsigned
      state.consignedMaxReturnValue = payload.consignedMaxReturnValue ?? consignedMaxReturnValue
      state.invoiceReturn = payload.invoiceReturn ?? invoiceReturn
      state.returnForm = payload.returnForm ?? returnForm
      state.grossValue = payload.grossValue ?? grossValue
      state.netValue = payload.netValue ?? netValue
      state.pendingPaymentValue = payload.pendingPaymentValue ?? pendingPaymentValue
      state.comission = payload.comission ?? comission
      state.comissionFee = payload.comissionFee ?? comissionFee
      state.returnSale = payload.returnSale ?? returnSale
      state.palmBeerData = payload.palmBeerData ?? palmBeerData
      state.tempDelegations = payload.tempDelegations ?? tempDelegations
      state.blockedPayBoxActions = payload.blockedPayBoxActions ?? blockedPayBoxActions
      state.echopeClassification = payload.echopeClassification ?? echopeClassification
      state.choppGiftCardData = payload.choppGiftCardData ?? choppGiftCardData
      state.electronicInvoiceRequired =
        payload.electronicInvoiceRequired ?? electronicInvoiceRequired
      state.electronicInvoiceInvoiceNumberGenerated =
        payload.electronicInvoiceInvoiceNumberGenerated ?? electronicInvoiceInvoiceNumberGenerated
    },
    SET_START_PAYMENT(state) {
      state.paymentStarted = true
    },
    SET_DELIVERY_DATA(state, { isDelivery, deliveryForm }) {
      const { isDelivery: defaultIsDelivery, deliveryForm: defaultDeliveryForm } = getInitialState()
      state.isDelivery = isDelivery ?? defaultIsDelivery
      state.deliveryForm = deliveryForm ?? defaultDeliveryForm
    },
    SET_RETURN_FORM(state, { returnForm }) {
      state.returnForm = returnForm
    },
    SET_BLOCKED_PAY_BOX_ACTIONS(state, { actions }) {
      state.blockedPayBoxActions = [...actions]
    },
    SET_ORDER_CHANGED(state, { changed }) {
      state.orderChanged = changed
    },

    SET_SALE_COMISSION(state, { comissionValue, comissionFee }) {
      state.comission = comissionValue
      state.comissionFee = comissionFee
    },
    SET_PAYMENT_FORM_RECEIPTS(state, receiptList) {
      state.paymentForm.receipts = receiptList
    },

    ADD_RECEIPT(state, receipt) {
      state.paymentForm.receipts = localListAddItem(state.paymentForm.receipts, receipt)
    },
    REMOVE_RECEIPT(state, { index, tefPaymentCancelation }) {
      const receiptFinded = state.paymentForm.receipts[index]
      if (receiptFinded) {
        const nsu = tefPaymentCancelation?.nsu ?? receiptFinded?.nsu
        const cardBrand = tefPaymentCancelation?.cardBrand ?? receiptFinded?.cardBrand
        const receiptUpdated = {
          ...receiptFinded,
          nsu,
          cardBrand,
          tefPaymentCancelation,
          canceled: true,
        }
        state.paymentForm.receipts.splice(index, 1, receiptUpdated)
      }
    },

    ADD_TEMP_DELEGATIONS(state, tempDelegation) {
      state.tempDelegations = [...state.tempDelegations, tempDelegation]
    },

    UPDATE_BALANCE(state, { change, balance }) {
      state.paymentForm.balance = balance
      state.paymentForm.change = change
    },

    CLEAN_ITEM_FORM_META(state) {
      const { amount, observation } = getInitialState().itemForm
      state.itemForm = { ...state.itemForm, amount, observation }
    },
    CLEAN_ITEM_FORM_PRODUCT(state) {
      const { product, discount } = getInitialState().itemForm
      state.itemForm = { ...state.itemForm, product, discount }
    },
    CLEAN_CLIENT_FORM(state) {
      const { clientForm, isDelivery, deliveryForm, electronicInvoiceRequired } = getInitialState()
      state.clientForm = clientForm
      state.isDelivery = isDelivery
      state.deliveryForm = deliveryForm
      state.electronicInvoiceRequired = electronicInvoiceRequired
    },
    CLEAN_CLIENT_FORM_WITHOUT_DOCUMENT(state) {
      const { clientForm, isDelivery, deliveryForm, electronicInvoiceRequired } = getInitialState()
      const { personType, document } = state.clientForm
      state.clientForm = { ...clientForm, personType, document }
      state.isDelivery = isDelivery
      state.deliveryForm = deliveryForm
      state.electronicInvoiceRequired = electronicInvoiceRequired
    },
    CLEAN_PALM_BEER_DATA(state) {
      const { palmBeerData } = getInitialState()
      state.palmBeerData = palmBeerData
    },
    CLEAN_CHOPP_GIFT_CARD_DATA(state) {
      const { choppGiftCardData } = getInitialState()
      state.choppGiftCardData = choppGiftCardData
    },
    CLEAN_STATE(state) {
      const defaultData = getInitialState()
      Object.keys(defaultData).forEach(key => {
        state[key] = defaultData[key]
      })
    },
  },

  actions: {
    async fetchClientByDocument({ dispatch, commit }, document) {
      if (!document) return
      const { LANDLINE, MOBILE } = telephoneTypes.computed.telephoneTypeEnum()
      const { data } = await axiosInstance.get(`/api/sales/pay-box-customer/search-by-document`, {
        params: { searchString: document || '' },
      })

      if (!data) {
        commit('CLEAN_CLIENT_FORM_WITHOUT_DOCUMENT')
      } else {
        const {
          id,
          personType,
          telephones,
          email,
          site,
          observation,
          name,
          priceTable,
          gender,
          birthdate,
          companyName,
          stateRegistrationType,
          stateRegistration,
          municipalInscription,
          addresses,
        } = data

        const defaultData = getInitialState().clientForm

        const dataFormated = {}

        if (telephones?.length) {
          dataFormated.landLine = telephones.find(t => t.type === LANDLINE)?.number || ''
          dataFormated.mobilePhone = telephones.find(t => t.type === MOBILE)?.number || ''
        }

        await dispatch('setClientForm', {
          ...dataFormated,
          id,
          personType,
          email,
          site,
          observation,
          name,
          priceTable: priceTable || null,
          gender,
          birthdate,
          companyName,
          stateRegistrationType,
          stateRegistration,
          municipalInscription,
          address: addresses.length > 0 ? addresses[0] : defaultData.address,
          addressList: addresses || [],
        })
      }
    },

    async fetchDeliveryTax(state, { addressData }) {
      // TODO buscar na API valor do frete
      console.log('buscar taxa de entrega para', addressData)
      return 10
    },

    async fetchPayBoxSale(state, { saleId, readOnly = false }) {
      const { data } = await axiosInstance.get(`/api/sales/pay-box-sale/${saleId}`, {
        params: { readOnly },
      })
      return payBoxSale.methods.prepareSale(data)
    },

    async searchChoppCard({ dispatch }, { cardNumber }) {
      await dispatch('cleanClientForm')
      const { data } = await axiosInstance.get(`/api/palmbeer/clients/by-card-number/${cardNumber}`)

      await dispatch(
        'pages/pdv/payBoxConfiguration/checkCurrentPayBoxConfigAndFetch',
        {},
        {
          root: true,
        }
      )

      if (!data.echopeCustomer) throw new Error('Dados retornados estão sem cliente.')
      dispatch('setClientForm', data.echopeCustomer)
      dispatch('setPalmBeerData', { customer: data })

      return data
    },

    async searchChoppGiftCardForSell({ dispatch, commit }, { cardNumber }) {
      await dispatch('cleanClientForm')
      const { data } = await axiosInstance.get(`/api/chopp-gift-cards/${cardNumber}/validate-sell`)
      commit('SET_CHOPP_GIFT_CARD_DATA', { ...data, operation: 'Sell' })
      return data
    },

    async searchChoppGiftCardForConversion({ dispatch, commit }, { cardNumber }) {
      await dispatch('cleanClientForm')
      const { data } = await axiosInstance.get(
        `/api/chopp-gift-cards/${cardNumber}/validate-conversion`
      )
      commit('SET_CHOPP_GIFT_CARD_DATA', { ...data, operation: 'Conversion' })
      return data
    },

    async loadSaleInPayBox({ state, dispatch, commit }, { saleId }) {
      const { clientForm, isDelivery: localIsDelivery, items: localItems, paymentForm } = state
      const haveOpenSale =
        localItems.length > 0 ||
        clientForm.document ||
        localIsDelivery ||
        paymentForm.receipts.length > 0

      if (haveOpenSale)
        throw new Error('Há venda em aberto, Finalize ou cancele ela para continuar esta ação.')

      await dispatch('cleanState')

      // TODO substituir com dispatch('fetchPayBoxSale', {saleId})
      const { data } = await axiosInstance.get(`/api/sales/pay-box-sale/${saleId}`)
      const {
        id: saleDataId,
        uuid,
        barTabConsumption,
        customer,
        priceTable,
        discountType: saleDiscountType,
        discount: saleDiscount,
        delivery: isDelivery,
        consigned: isConsigned,
        consignedMaxReturnValue,
        deliveryTaxDiscountValue,
        deliveryTax,
        deliveryObservation,
        deliveryAddress,
        deliveryStatus,
        offline,
        items,
        itemsReturned,
        itemKits,
        payments,
        grossValue: saleGrossValue,
        netValue: saleNetValue,
        comission,
        returnSale,
        echopeClassification,
        pendingPaymentValue,
        coldOption,
      } = data

      const { cellphone, phone } = contactUtils.getPricipalMobileAndLandline(customer.telephones)
      const defaultData = getInitialState()

      const itemsFormated = await dispatch('prepareSaleItems', items)

      const saleItems = itemsFormated.filter(i => !i.productKitId)

      const itemsFromKit = itemsFormated.filter(i => i.productKitId)
      let kitItems = itemKits.map(kit => {
        const itemsOfKit = itemsFromKit
          .filter(i => i.productKitId === kit.productKit.id)
          .map(i => {
            const product = kit.productKitEnriched.kitItems.find(
              ki => ki.skuId === i.product.skuId
            )

            return {
              ...i,
              product: { ...product, lendingItemAssociated: i.product?.lendingItemAssociated },
            }
          })

        const { netValue, priceInfo } = payBoxUtils.calculateItemPrice({
          price: kit.unitValue,
          quantity: kit.quantity,
        })
        return {
          ...defaultData.itemForm,
          id: kit.id,
          orderAmount: kit.quantity,
          amount: kit.quantity,
          product: {
            ...kit.productKitEnriched,
            kitItems: itemsOfKit,
          },
          subtotal: netValue,
          unitValue: kit.unitValue,
          priceInfo: {
            priceWithDiscount: priceInfo.priceWithDiscount,
            valueDiscounted: priceInfo.discountTotal,
            subtotalWithoutDiscount: priceInfo.grossValue,
          },
        }
      })
      kitItems = await Promise.all(
        kitItems.map(kit => dispatch('calculateItemPriceInKitItem', { item: kit }))
      )

      const receipts = payments.map(p => ({
        ...getInitialReceiptForm(),
        ...p,
      }))
      const itemsOfSale = [...saleItems, ...kitItems]
      const productTotalValue = itemsOfSale.reduce((total, i) => total + i.subtotal, 0)

      const formatedData = {
        ...defaultData,
        id: saleDataId,
        uuid,
        barTabConsumption,
        comission,
        comissionFee: barTabConsumption?.comissionFee ?? null,
        grossValue: saleGrossValue,
        netValue: saleNetValue,
        pendingPaymentValue,
        priceTable,
        echopeClassification,
        offline,
        coldOption,
        items: itemsOfSale,
        itemsReturned,
        returnSale,
        // tipo do desconto está apenas para valor (quando vier em porcentagem, é convertido para valor)
        paymentForm: {
          ...defaultData.paymentForm,
          discount: payBoxUtils.getDiscountValue({
            discountType: saleDiscountType,
            discountValue: saleDiscount,
            totalValue: productTotalValue,
          }),
          balance: 0,
          receipts,
        },
        isDelivery,
        isConsigned,
        consignedMaxReturnValue: consignedMaxReturnValue || 0,
        deliveryForm: {
          ...defaultData.deliveryForm,
          address: deliveryAddress,
          observation: deliveryObservation,
          addressSelected: '',
          deliveryTax,
          deliveryTaxDiscountValue,
          deliveryStatus,
        },
        clientForm: {
          ...defaultData.clientForm,
          ...customer,
          landLine: phone,
          mobilePhone: cellphone,
          site: customer.siteUrl,
          address:
            customer.addresses.length > 0 ? customer.addresses[0] : defaultData.clientForm.address,
          addressList: customer.addresses || [],
        },
      }

      if (isConsigned) {
        formatedData.returnForm = await dispatch('prepareReturnedItems', {
          items: formatedData.items,
          itemsReturned: formatedData.itemsReturned,
          returnSale,
        })
      }

      commit('SET_ORDER_CHANGED', { changed: false })
      commit('SET_ALL_STATE', formatedData)
      await dispatch('updateBalance')
      await dispatch('updatePdvSession')
    },
    prepareSaleItems(states, items) {
      // TODO utilizar payBoxSale.methods.prepareSaleItems(items)
      const defaultData = getInitialState()
      const { LENDING } = productDomains.computed.productClassificationsEnum()

      const lendingItems = items.filter(i => i.classification === LENDING)

      // const isLendingItemInKit = i => i.productKitId && i.classification === LENDING
      return _.sortBy(items, ['id'])
        .filter(i => !i.saleItemAssociatedId)
        .map(i => {
          const discountValue = payBoxUtils.getDiscountValue({
            discountType: i.discountType,
            discountValue: i.unitDiscount,
            totalValue: i.unitValue,
            priceTableDiscount: i.discountPriceTable,
            promotionId: i.promotionId,
          })
          const lp = i.skuEnriched?.lendingProductAssociated
          let localMainSkuEnriched = i.skuEnriched || i.sku

          const itemLpFound = lendingItems.find(
            lendingItem => i.id === lendingItem.saleItemAssociatedId
          )
          if (lp && itemLpFound) {
            const lendingItemsList = !itemLpFound
              ? []
              : [itemLpFound].map(itemLp => ({
                  ...defaultData.itemForm,
                  // ...itemLp,
                  id: itemLp.id,
                  orderAmount: itemLp.quantity,
                  amount: itemLp.quantity,
                  product: {
                    ...lp,
                    skuId: itemLp.sku.id,
                  },
                  saleItemAssociatedId: itemLp.saleItemAssociatedId,
                  skuAssociatedId: itemLp.skuAssociatedId,
                  priceTableId: itemLp.priceTableId,
                  inputMethodEnum: i.inputMethod,
                  returnDate: itemLp.returnDate,
                  returnDateLimit: itemLp.returnDateLimit,
                  deliveredDate: i.deliveredDate,
                  createdDate: i.createdDate,
                  createdBy: i.createdBy,
                  returnQuantityDamaged: itemLp.returnQuantityDamaged,
                  palmBeerCardNumber: i.palmBeerCardNumber || null,
                  choppGiftCardNumber: i.choppGiftCardNumber || null,
                  choppGiftCardId: i.choppGiftCardId || null,
                }))

            localMainSkuEnriched = {
              ...localMainSkuEnriched,
              lendingItemAssociated: lendingItemsList,
            }
          }

          return {
            ...defaultData.itemForm,
            id: i.id,
            classification: i.classification,
            productKitId: i.productKitId,
            inputMethodEnum: i.inputMethod,
            discountType: i.discountType,
            discount: i.unitDiscount,
            observation: i.observation,
            product: localMainSkuEnriched,
            orderAmount: i.quantity,
            amount: i.quantity,
            maxAmount: i.promotionId ? i.quantity : defaultData.itemForm.maxAmount,
            priceTableId: i.priceTableId,
            promotionId: i.promotionId,
            discountPriceTable: i.discountPriceTable || 0,
            unitValue: i.unitValue,
            subtotal: i.netValue,
            returnDate: i.returnDate,
            returnDateLimit: i.returnDateLimit,
            deliveredDate: i.deliveredDate,
            createdDate: i.createdDate,
            createdBy: i.createdBy,
            returnQuantityDamaged: i.returnQuantityDamaged,
            palmBeerCardNumber: i.palmBeerCardNumber || null,
            choppGiftCardNumber: i.choppGiftCardNumber || null,
            choppGiftCardId: i.choppGiftCardId || null,
            priceInfo: {
              priceWithDiscount: roundDecimal(i.unitValue - discountValue, 4),
              valueDiscounted: roundDecimal(i.grossValue - i.netValue, 4),
              subtotalWithoutDiscount: i.grossValue,
            },
          }
        })
    },
    prepareReturnedItems(states, { items, itemsReturned, returnSale }) {
      // TODO utilizar payBoxSale.methods.prepareReturnedItems(items)
      const { SALE, RENT, LENDING } = productDomains.computed.productClassificationsEnum()
      const defaultItemReturned = getInitialPayBoxReturnedItem()
      const itemsFormatted = items
        .map(i => {
          const itemList = []

          if (i.product.kitItems) {
            return i.product.kitItems.map(ki => ({
              ...ki,
              kitData: i.product,
            }))
          }

          itemList.push(i)
          const lendingItemList = i.product.lendingItemAssociated
          if (lendingItemList && Array.isArray(lendingItemList)) {
            lendingItemList.forEach(li => {
              itemList.push({
                ...li,
                lendingData: i.product, // TODO mudar para AssociatedData
              })
            })
          }
          return itemList
        })
        .flat()

      const validItemsReturned = Array.isArray(itemsReturned) ? itemsReturned : []
      const alreadyReturnedFormatted = validItemsReturned.map(ir => {
        const priceInfo = payBoxUtils.calculateItemPrice({
          price: ir.unitValue,
          quantity: ir.quantity,
          discountType: ir.discountType,
          unitDiscount: ir.unitDiscount,
          priceTableDiscount: ir.discountPriceTable,
          promotionId: ir.promotionId,
        })
        return {
          ...defaultItemReturned,
          itemReturnedId: ir.id,
          itemId: ir.saleItemId,
          product: { ...ir.sku, classification: ir.classification, skuId: ir.sku.id },
          amount: ir.quantity,
          maxAmount: ir.quantity,
          unitValue: ir.unitValue,
          subtotal: ir.netValue,
          unitValueCalculated: priceInfo.priceInfo.priceWithDiscount,
          subtotalCalculated: priceInfo.netValue,
          returnDate: ir.createdDate,
          deliveredDate: ir.deliveredDate,
          createdDate: ir.createdDate,
          createdBy: ir.createdBy,
          discountType: ir.discountType,
          unitDiscount: ir.unitDiscount,
        }
      })

      const formatted = itemsFormatted.map(i => ({
        ...defaultItemReturned,
        itemId: i.id,
        product: i.product,
        maxAmount: i.amount,
        damagedAmount: i.returnQuantityDamaged || 0,
        unitValueCalculated: i.priceInfo.priceWithDiscount,
        subtotalCalculated: 0,
        kit: i.kitData || defaultItemReturned.kit,
        lendingProduct: i.lendingData || defaultItemReturned.lendingProduct,
        returnDate: i.returnDate || defaultItemReturned.returnDate,
        returnDateLimit: i.returnDateLimit || defaultItemReturned.returnDateLimit,
        deliveredDate: i.deliveredDate,
        createdDate: i.createdDate,
        createdBy: i.createdBy,
      }))

      const allItems = [...alreadyReturnedFormatted, ...formatted]

      const defaultReturnForm = getInitialConsignReturnForm()
      return {
        ...defaultReturnForm,
        returnedItems: allItems.filter(i => i.product.classification === SALE),
        returnedLendingItems: allItems.filter(
          i => i.product.classification === LENDING || i.product.classification === RENT
        ),
        value: returnSale?.value ?? defaultReturnForm.value,
        paymentMethod: returnSale?.paymentMethod ?? defaultReturnForm.paymentMethod,
        observation: returnSale?.observation ?? defaultReturnForm.observation,
      }
    },

    setItemFormProduct({ commit, state }, product) {
      // TODO usar enrichProductToSaleItem em pay-box-sale
      let localProduct = product
      const lp = product.lendingProductAssociated
      if (lp) {
        localProduct = {
          ...localProduct,
          lendingItemAssociated: [
            {
              ...getInitialPayBoxItem(),
              product: { ...lp },
              amount: state.itemForm.amount,
              priceTableId: localProduct.priceTable.id,
              contractualFine: lp.contractualFine,
              inputMethodEnum: null,
            },
          ],
        }
      }

      commit('SET_ITEM_FORM_PRODUCT', localProduct)
    },
    async updatePdvSession({ dispatch }) {
      await dispatch('upsertIdbSession')
    },
    setDiscountPaymentForm({ commit, dispatch }, value) {
      commit('SET_DISCOUNT_PAYMENT_FORM', value)
      dispatch('updateBalance')
    },
    async setStartPayment({ commit, state }) {
      commit('SET_START_PAYMENT')
      state.items = state.items.map(i => ({ ...i, paymentStarted: true }))
    },
    async setElectronicInvoiceRequired({ commit }, value) {
      commit('SET_ELECTRONIC_INVOICE_REQUIRED', value)
    },

    async setClientForm({ commit, state, dispatch }, client) {
      const form = { ...state.clientForm, ...client }
      commit('SET_CLIENT_FORM', form)
      dispatch('pages/pdv/saleProducts/cleanProducts', null, { root: true })
    },
    async searchAndSetClient({ commit, dispatch }, { document }) {
      const getDocumentType = localDocument => {
        const { CONSUMER, INDIVIDUAL, JURIDICAL } = personTypes.computed.personTypeEnum()
        const cleanedText = localDocument?.replace(/[./-]/g, '') || ''

        if (cleanedText.length < 11) {
          return CONSUMER
        }

        if (cleanedText.length === 11) {
          return INDIVIDUAL
        }

        return JURIDICAL
      }

      if (!validateBr.cpfcnpj(document || '')) {
        commit('CLEAN_CLIENT_FORM')
        return
      }

      const cleanedText = document.replace(/[./-]/g, '')

      await dispatch('setClientForm', {
        document: cleanedText,
        personType: getDocumentType(cleanedText),
      })

      await dispatch('fetchClientByDocument', cleanedText)
    },

    setItemFormAmount({ commit }, amount) {
      commit('SET_ITEM_FORM_AMOUNT', amount)
    },
    async setPalmBeerData({ commit, state }, data) {
      const form = { ...state.palmBeerData, ...data }
      commit('SET_PALM_BEER_DATA', form)
    },
    async setDeliveryData({ commit, dispatch }, { isDelivery, deliveryForm }) {
      commit('SET_DELIVERY_DATA', { isDelivery, deliveryForm })
      await dispatch('updateBalance')
    },
    async setReturnForm({ commit, state, dispatch }, { returnForm }) {
      const { items } = state
      returnForm.returnedItems.forEach(async returnedItem => {
        const kitIndex = items.findIndex(
          i =>
            i.product.kitItems && i.product.kitItems.some(ki => ki.id === returnedItem.itemId)
        )
        if (kitIndex > -1) {
          const kit = items[kitIndex]
          const kitItemIndex = kit.product.kitItems.findIndex(ki => ki.id === returnedItem.itemId)
          if (kitItemIndex > -1) {
            const kitItems = kit.product.kitItems.splice(kitItemIndex, 1, {
              ...kit.product.kitItems[kitItemIndex],
              amount: returnedItem.maxAmount - returnedItem.amount,
            })
            const payload = { ...kit, product: { ...kit.product, kitItems } }
            await dispatch('updateItem', { payload })
          }
          return
        }

        const itemIndex = items.findIndex(i => i.id === returnedItem.itemId)
        if (itemIndex > -1) {
          const payload = {
            ...items[itemIndex],
            amount: returnedItem.maxAmount - returnedItem.amount,
          }
          await dispatch('updateItem', { payload })
        }
      })

      commit('SET_ORDER_CHANGED', { changed: true })
      commit('SET_RETURN_FORM', { returnForm })
    },

    async addItem(
      { commit, dispatch, state },
      { item, inputMethod, palmBeerCardNumber, choppGiftCardId, choppGiftCardNumber }
    ) {
      const { promotionQuantityAvailable, promotionQuantityUnlimited, promotionId } = item.product
      const existingItems = [...state.items]
      const itemFoundIndex = existingItems.findIndex(
        i =>
          i.localId &&
          i.product.id === item.product.id &&
          i.product.kit === item.product.kit &&
          i.product.promotionId === item.product.promotionId &&
          !i.choppGiftCardNumber
      )
      let newItem
      let quantityWithoutPromotion = 0

      if (itemFoundIndex < 0) {
        newItem = { ...item }
      } else {
        // feito splice pra retirar da posição atual, pois dps irá ser adicionado pro inicio da lista
        const itemFound = existingItems.splice(itemFoundIndex, 1)[0]
        let oldAmount = itemFound.amount
        if (itemFound.isDeleted) oldAmount -= 1
        const amount = Number(oldAmount) + Number(item.amount)
        newItem = { ...itemFound, amount, isDeleted: false }
      }

      if (promotionId && !promotionQuantityUnlimited) {
        quantityWithoutPromotion = newItem.amount - (promotionQuantityAvailable || 0)
        newItem.amount =
          quantityWithoutPromotion > 0 ? newItem.amount - quantityWithoutPromotion : newItem.amount
      }

      const calcItemPrice = await dispatch('calculateItemPrice', {
        item: newItem,
        newAmount: newItem.amount,
      })
      newItem = {
        ...newItem,
        ...calcItemPrice,
        hasColdOption: newItem.product?.hasColdOption,
        inputMethodEnum: inputMethod,
        palmBeerCardNumber,
        choppGiftCardId,
        choppGiftCardNumber,
        promotionId,
      }

      newItem = await dispatch('calculateKitItemPrice', { item: newItem })

      const newItems = localListAddItem(existingItems, newItem, true)

      commit('SET_ORDER_CHANGED', { changed: true })
      commit('SET_ITEMS', newItems)
      await dispatch('updateBalance')
      await dispatch('setAndCalculateComission', { comissionFee: state.comissionFee })

      if (promotionId && quantityWithoutPromotion > 0) {
        await dispatch('addNewItemWithoutPromotion', { newItem, quantityWithoutPromotion })
      }
    },
    async removeItem({ commit, dispatch, state, getters }, item) {
      await dispatch('updateItem', { payload: { ...item, amount: 1 } })

      const newItems = localListDeleteItem(state.items, item.id || item.localId)
      commit('SET_ITEMS', newItems)

      if (!getters.hasCreditItems) {
        await dispatch('cleanPalmBeerData')
      }

      commit('SET_ORDER_CHANGED', { changed: true })
      dispatch('updateBalance')
      await dispatch('setAndCalculateComission', { comissionFee: state.comissionFee })
    },
    async updateItem({ commit, state, dispatch, getters }, { payload }) {
      const { promotionQuantityAvailable, promotionQuantityUnlimited } = payload.product
      const promotionId = payload.promotionId || payload.product.promotionId
      let quantityWithoutPromotion = 0
      let newAmount = Number(payload.amount)

      const indexFound = state.items.findIndex(
        v => (v.id && v.id === payload.id) || (v.localId && v.localId === payload.localId)
      )
      const oldItem = state.items[indexFound]

      if (promotionId && !promotionQuantityUnlimited) {
        if (payload.id) {
          // cenário que está sendo atualizado um produto com promoção adicionado no pedido
          if (newAmount > payload.maxAmount) {
            await dispatch('addNewItemWithoutPromotion', {
              newItem: payload,
              quantityWithoutPromotion: newAmount - payload.maxAmount,
            })
            return
          }
        } else {
          quantityWithoutPromotion = newAmount - (promotionQuantityAvailable || 0)
          newAmount =
            quantityWithoutPromotion > 0 ? newAmount - quantityWithoutPromotion : newAmount
        }
      }

      const calcItemPrice = await dispatch('calculateItemPrice', {
        item: oldItem,
        newAmount,
      })
      let itemUpdated = { ...oldItem, ...calcItemPrice, amount: newAmount }

      if (getters.isConsignedSaleFinish) {
        itemUpdated = await dispatch('calculateItemPriceInKitItem', { item: itemUpdated })
      } else {
        itemUpdated = await dispatch('calculateKitItemPrice', { item: itemUpdated, isUpdate: true })
      }

      const newItems = localListUpdateItem(state.items, itemUpdated)
      commit('SET_ORDER_CHANGED', { changed: true })
      commit('SET_ITEMS', newItems)
      await dispatch('updateBalance')
      await dispatch('setAndCalculateComission', { comissionFee: state.comissionFee })

      if (promotionId && quantityWithoutPromotion > 0) {
        await dispatch('addNewItemWithoutPromotion', {
          newItem: itemUpdated,
          quantityWithoutPromotion,
        })
      }
    },

    async addNewItemWithoutPromotion({ dispatch }, { newItem, quantityWithoutPromotion }) {
      await dispatch('addItem', {
        item: {
          ...newItem,
          localId: null,
          id: null,
          amount: quantityWithoutPromotion,
          promotionId: null,
          product: {
            ...newItem.product,
            promotionId: null, // necessário para que não tenha loop ao adicionar item
            price: newItem.product.originalPrice,
            priceFrom: null,
          },
          unitValue: null,
        },
        inputMethod: newItem.inputMethodEnum,
        palmBeerCardNumber: newItem.palmBeerCardNumber,
        choppGiftCardNumber: newItem.choppGiftCardNumber,
        choppGiftCardId: newItem.choppGiftCardId,
      })
    },

    async setAndCalculateComission({ commit, getters, dispatch }, { comissionFee }) {
      let comissionValue = 0
      if (comissionFee > 0) {
        // getSubtotalSale is the netValue
        comissionValue = roundDecimal(getters.getSubtotalSale * comissionFee, 2)
      }

      commit('SET_SALE_COMISSION', { comissionValue, comissionFee: comissionFee || null })
      await dispatch('updateBalance')
    },

    async calculateKitItemPrice(states, { item, isUpdate = false }) {
      const defaultItemForm = getInitialState().itemForm
      const itensInKit = item.product.kitItems

      if (Array.isArray(itensInKit) && itensInKit.length > 0) {
        const kitItems = itensInKit.map(ki => {
          let itemData
          if (isUpdate) {
            // ki é item
            itemData = { ...ki, amount: ki.product.quantity * item.amount }
          } else if (ki.product) {
            // ki é item
            itemData = {
              ...ki,
              amount: ki.product.quantity * item.amount,
            }
          } else {
            // ki é produto
            itemData = {
              ...defaultItemForm,
              discountType: ki.discountType,
              productKitId: item.product.id,
              priceTableId: item.product.priceTable?.id || null,
              product: { ...ki },
              amount: ki.quantity * item.amount,
            }
          }

          const { netValue, priceInfo } = payBoxUtils.calculateItemPrice({
            price: itemData.unitValue || itemData.product.price,
            quantity: itemData.amount,
            discountType: itemData.discount ? itemData.discountType : itemData.product.discountType,
            unitDiscount: itemData.discount || itemData.product.unitDiscount,
            priceTableDiscount: itemData.discountPriceTable,
            promotionId: itemData.promotionId,
          })

          return {
            ...itemData,
            subtotal: netValue,
            priceInfo: {
              priceWithDiscount: priceInfo.priceWithDiscount,
              valueDiscounted: priceInfo.discountTotal,
              subtotalWithoutDiscount: priceInfo.grossValue,
            },
          }
        })

        return { ...item, product: { ...item.product, kitItems } }
      }

      return item
    },
    async calculateItemPriceInKitItem({ dispatch }, { item }) {
      const itensInKit = item.product.kitItems
      if (!Array.isArray(itensInKit) || itensInKit?.length === 0) {
        return item
      }

      const kitItems = await Promise.all(
        itensInKit.map(async ki => {
          const calcItem = await dispatch('calculateItemPrice', { item: ki })
          return { ...ki, ...calcItem }
        })
      )

      const kitSubtotal = kitItems.reduce(
        (total, i) => new Decimal(total).add(i.subtotal || 0).toNumber(),
        0
      )

      return {
        ...item,
        product: { ...item.product, kitItems },
        subtotal: kitSubtotal,
        priceInfo: {
          priceWithDiscount: kitSubtotal,
          valueDiscounted: 0,
          subtotalWithoutDiscount: kitSubtotal,
        },
      }
    },

    calculateItemPrice(states, { item, newAmount }) {
      const {
        product,
        amount,
        discount: itemDiscount,
        discountType: itemDiscountType,
        unitValue,
        discountPriceTable,
        promotionId,
      } = item
      const { price, unitDiscount: productDiscount, discountType: productDiscountType } = product
      const quantity = Number.isNaN(Number(newAmount)) ? amount : newAmount

      const { netValue, priceInfo } = payBoxUtils.calculateItemPrice({
        price: unitValue || price,
        quantity: Number(quantity),
        discountType: itemDiscount ? itemDiscountType : productDiscountType,
        unitDiscount: itemDiscount || productDiscount,
        priceTableDiscount: discountPriceTable,
        promotionId,
      })
      return {
        subtotal: netValue,
        priceInfo: {
          priceWithDiscount: priceInfo.priceWithDiscount,
          valueDiscounted: priceInfo.discountTotal,
          subtotalWithoutDiscount: priceInfo.grossValue,
        },
      }
    },

    async addChoppGiftCardReceipt({ commit, dispatch, getters }) {
      const { data } = await axiosInstance.get(
        '/api/settings/financial/payment-methods/chopp-gift-card',
        { params: { pageSize: 0, pageIndex: 0 } }
      )
      const receipt = getInitialReceiptForm()
      receipt.value = getters.getTotalSale
      receipt.paymentMethod.id = data.id
      receipt.paymentMethod.method = data.method
      receipt.paymentMethod.name = data.name
      receipt.paymentMethodInterface = 'Manual'
      commit('ADD_RECEIPT', receipt)
      dispatch('updateBalance')
    },

    addReceipt({ commit, dispatch }, receipt) {
      commit('SET_ORDER_CHANGED', { changed: true })
      commit('ADD_RECEIPT', receipt)
      dispatch('updateBalance')
    },
    removeReceipt({ commit, dispatch }, { index, tefPaymentCancelation }) {
      commit('SET_ORDER_CHANGED', { changed: true })
      commit('REMOVE_RECEIPT', { index, tefPaymentCancelation })
      dispatch('updateBalance')
    },
    async printReceipt({ state, getters, dispatch, rootGetters }, { index }) {
      await dispatch(
        'pages/pdv/payBoxConfiguration/checkCurrentPayBoxConfigAndFetch',
        {},
        {
          root: true,
        }
      )

      const printerConfig =
        rootGetters['pages/pdv/payBoxConfiguration/thermalPrinterAgentConfigPayload']

      const payboxConfig = rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']
      const isPdvAtm = rootGetters['pages/pdv/payBoxConfiguration/isPdvAtm']

      const { id: saleId, uuid: saleUuid } = state

      const { receipts } = state.paymentForm
      const receipt = index === -1 ? receipts[receipts.length - 1] : receipts[index]

      const { tefPayment, tefPaymentCancelation } = receipt

      const isManualPix =
        receipt.paymentMethod.method === paymentTypeEnum.PIX &&
        receipt.paymentMethodInterface === paymentMethodInterfaceEnum.MANUAL

      if (tefPayment || tefPaymentCancelation) {
        const tefPayload = receipt.canceled ? tefPaymentCancelation : tefPayment

        const couponStore = {
          printText: tefPayload.couponStore,
          template: 'PAYMENT_GETNET',
        }
        const couponCustomer = {
          printText: tefPayload.couponCustomer,
          template: 'PAYMENT_GETNET',
        }

        const printTexts = isPdvAtm ? [couponCustomer] : [couponStore, couponCustomer]

        try {
          await window.electronAPI.print({
            ...printerConfig,
            request: {
              openCashDrawer: false,
              printTexts,
            },
          })
        } catch (error) {
          console.error('error on print tef', error)
        }
      } else if (isManualPix) {
        try {
          await window.electronAPI.print({
            ...printerConfig,
            request: {
              openCashDrawer: false,
              printTexts: [
                {
                  printText: JSON.stringify({
                    title: 'Comprovante PIX',
                    copyNumbers: 1,
                    saleId: (saleId || saleUuid).toString(),
                    payBox: payboxConfig,
                    ...receipt,
                  }),
                  template: 'PAYMENT_GENERIC',
                },
              ],
            },
          })
        } catch (error) {
          console.error('error on print generic payment - pix', error)
        }
      } else if (getters.isConsignedSalePayment) {
        try {
          await window.electronAPI.print({
            ...printerConfig,
            request: {
              openCashDrawer: false,
              printTexts: [
                {
                  printText: JSON.stringify({
                    saleId: (saleId || saleUuid).toString(),
                    payBox: payboxConfig,
                    ...receipt,
                  }),
                  template: 'PAYMENT_GENERIC',
                },
              ],
            },
          })
        } catch (error) {
          console.error('error on print generic payment - consigned', error)
        }
      }
      // else if (state.paymentForm?.change > 0) {
      //   try {
      //     await window.electronAPI.openCashDrawer(printerConfig)
      //   } catch (error) {
      //     /* eslint-disable no-console */
      //     console.error('error on open cash drawer', error)
      //   }
      // }
    },

    async updateBalance({ commit, getters, dispatch }) {
      const result = roundDecimal(getters.getTotalSale - getters.getTotalReceipt)
      let change = 0
      let balance = 0

      if (result < 0) {
        change = Math.abs(result)
      } else {
        balance = result
      }

      commit('UPDATE_BALANCE', { change, balance })
      await dispatch('updateReceiptChanges', { change })
    },
    updateReceiptChanges({ state, commit }, { change }) {
      const cashReceipts = state.paymentForm.receipts.filter(
        r => !r.canceled && !r.id && r.paymentMethod?.method === paymentTypeEnum.CASH
      )
      if (cashReceipts.length > 0) {
        cashReceipts.forEach(r => ({ ...r, change: 0 }))

        const lastReceiptIndex = cashReceipts.length - 1
        cashReceipts[lastReceiptIndex] = { ...cashReceipts[lastReceiptIndex], change }

        let receiptsList = state.paymentForm.receipts
        cashReceipts.forEach(r => {
          receiptsList = localListUpdateItem(receiptsList, r)
        })
        commit('SET_PAYMENT_FORM_RECEIPTS', receiptsList)
      }
    },

    async finishSale({ dispatch, state, getters, rootGetters }, coldOption) {
      await dispatch(
        'pages/pdv/payBoxConfiguration/checkCurrentPayBoxConfigAndFetch',
        {},
        {
          root: true,
        }
      )

      state.coldOption = state.coldOption ?? coldOption
      let invoiceResult = {}
      if (
        (!state.isConsigned || getters.isConsignedSaleFinish) &&
        getters.hasSaleItems &&
        isElectron() &&
        !state.invoiceReturn &&
        !getters.isChoppGiftCardConversion
      ) {
        if (!state.electronicInvoiceRequired) {
          invoiceResult = await dispatch('generateInvoice')
        } else {
          invoiceResult = await dispatch('generateNFe')
        }
      }

      await dispatch('finishSaleSaveOnServer')
      // devemos tomar cuidado adicionando métodos depois do `finishSaleSaveOnServer`, pois podemos salvar a venda e não continuar o fluxo até limpar a venda no PDV

      const printerConfig =
        rootGetters['pages/pdv/payBoxConfiguration/thermalPrinterAgentConfigPayload']

      try {
        window.electronAPI.openCashDrawer(printerConfig)
      } catch (err) {
        console.error(err)
      }

      return {
        ...invoiceResult,
      }
    },

    async finishSaleSaveOnServer({ dispatch, state, getters, rootGetters }) {
      const {
        store: payBoxStore,
        id: payBoxId,
        eventId,
      } = rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']

      const {
        id: saleId,
        uuid,
        items,
        clientForm,
        paymentForm,
        isDelivery,
        deliveryForm,
        invoiceReturn,
        returnForm,
        tempDelegations,
        comission,
        coldOption,
      } = state // getInitialState()
      const { INDIVIDUAL, JURIDICAL } = personTypes.computed.personTypeEnum()
      const customerTypeEnum = {
        CONSUMER: 'Consumer',
        NEW_CUSTOMER: 'NewCustomer',
        EXISTING_CUSTOMER: 'ExistingCustomer',
      }

      let customer = {
        customerType: customerTypeEnum.CONSUMER,
        id: clientForm?.id || null,
      }
      if (getters.isBarConsumption && !customer.id) {
        customer.id = 1
      }
      if (clientForm.personType === INDIVIDUAL || clientForm.personType === JURIDICAL) {
        if (clientForm.id) {
          customer = {
            customerType: customerTypeEnum.EXISTING_CUSTOMER,
            name: clientForm.name?.trim(),
            id: clientForm.id,
            companyName: clientForm.companyName?.trim(),
          }
        } else {
          // FIXME usar Array.some()
          let hasAddress = false
          Object.keys(clientForm.address).forEach(a => {
            if (clientForm.address[a]) {
              hasAddress = true
            }
          })

          customer = {
            customerType: customerTypeEnum.NEW_CUSTOMER,
            id: null,
            personType: clientForm.personType,
            stateRegistration: clientForm.stateRegistration,
            stateRegistrationType: clientForm.stateRegistrationType,
            document: clientForm.document,
            name: clientForm.name?.trim(),
            email: clientForm.email?.trim(),
            observation: clientForm.observation,
            birthdate: clientForm.birthdate,
            landlinePhone: clientForm.landLine,
            mobilePhone: clientForm.mobilePhone,
            address: hasAddress ? clientForm.address : null,
            companyName: clientForm.companyName?.trim(),
          }
        }
      }

      const purchasedProducts = items
        .map(item => {
          const { product } = item
          if (product.kit) {
            return product.kitItems
              .flatMap(kitItem => {
                if (kitItem.product?.lendingItemAssociated?.length > 0) {
                  return [kitItem, kitItem.product.lendingItemAssociated[0]]
                }
                return kitItem
              })
              .map(kitItem => ({
                id: kitItem.id,
                skuId: kitItem.product.skuId,
                productKitId: product.id,
                customerRequired: kitItem.product.customerRequired,
                skuAssociatedId: kitItem?.skuAssociatedId || null,
                quantity: kitItem.amount,
                unitValue: kitItem.unitValue || kitItem.product.price,
                priceTableId: null,
                discountPriceTable: 0,
                discountType: kitItem.discount
                  ? kitItem.discountType
                  : kitItem.product.discountType,
                unitDiscount: kitItem.discount || kitItem.product.unitDiscount || 0,
                inputMethod: kitItem.inputMethodEnum || item.inputMethodEnum,
                contractualFine: kitItem.product.contractualFine,
                isDeleted: item.isDeleted,
                palmBeerCardNumber:
                  kitItem.product.classification === 'Credit' ? item.palmBeerCardNumber : undefined,
                choppGiftCardId: item.choppGiftCardId,
              }))
          }

          return {
            id: item.id,
            skuId: product.skuId,
            productKitId: null,
            skuAssociatedId: null,
            quantity: item.amount,
            customerRequired: item.product.customerRequired,
            unitValue: item.unitValue || product.price,
            priceTableId: item.priceTableId || product.priceTable?.id || null,
            discountPriceTable: _.isNil(item.discountPriceTable)
              ? product.priceTable?.discount
              : item.discountPriceTable,
            discountType: item.discountType,
            unitDiscount: item.discount || 0, // TODO desconto dado pelo usuário pro item em específico
            contractualFine: item.product.contractualFine,
            inputMethod: item.inputMethodEnum,
            isDeleted: item.isDeleted,
            promotionId: item.promotionId,
            choppGiftCardId: item.choppGiftCardId,
          }
        })
        .flat()

      const itemKits = items
        .filter(item => item.product.kit)
        .map(item => ({
          id: item.id,
          productKitId: item.product.id,
          quantity: item.amount,
          unitValue: item.unitValue || item.product.price,
          isDeleted: item.isDeleted,
        }))

      const lendingProducts = items
        .filter(item => !!item.product.lendingItemAssociated)
        .map(item => {
          const lpItemList = item.product.lendingItemAssociated

          return lpItemList.map(lpItem => ({
            id: lpItem.id,
            skuId: lpItem.product.skuId,
            productKitId: null,
            skuAssociatedId: item.product.skuId,
            lending: true,
            customerRequired: item.product.customerRequired,
            quantity: lpItem.amount, // lpItem.product.quantity * item.amount,
            unitValue: lpItem.unitValue || lpItem.product.price || 0,
            priceTableId: lpItem.priceTableId || item.product.priceTable?.id || null,
            returnDateLimit: lpItem.returnDateLimit,
            contractualFine: lpItem.contractualFine || lpItem.product.contractualFine,
            discountType: null,
            discountPriceTable: null,
            unitDiscount: null,
            inputMethod: item.inputMethodEnum,
            isDeleted: item.isDeleted,
          }))
        })
        .flat()

      const payments = paymentForm.receipts.map(r => ({
        id: r.id,
        paymentMethodId: r.paymentMethod.id,
        paymentMethodInterface: r.paymentMethodInterface,
        installment: 1,
        value: r.value,
        canceled: r.canceled,
        change: r.change || 0,
        nsu: r.nsu,
        cardBrand: r.cardBrand,
        documentTitular: r.documentTitular,
        tefPayment: r.tefPayment,
        tefPaymentCancelation: r.tefPaymentCancelation,
      }))

      const deliveryData = {}

      if (isDelivery) {
        deliveryData.deliveryTax = deliveryForm.deliveryTax
        deliveryData.deliveryAddress = deliveryForm.address
        deliveryData.deliveryObservation = deliveryForm.observation
      }

      let invoiceData

      if (invoiceReturn) {
        invoiceData = {
          ...invoiceReturn,
          storeId: payBoxStore.id,
        }
      }

      // data to finish consigned sale
      let returnSale = null
      if (getters.isConsignedSaleFinish && returnForm.paymentMethod) {
        returnSale = {
          value: returnForm.value,
          paymentMethodId: returnForm.paymentMethod,
          observation: returnForm.observation,
        }
      }

      const itemsReturned = returnForm.returnedItems
        .filter(ri => ri.amount > 0)
        .map(ri => ({
          saleItemId: ri.itemId,
          id: ri.itemReturnedId,
          skuId: ri.product.skuId,
          quantity: ri.amount,
          unitValue: ri.unitValue,
        }))

      const data = {
        id: saleId,
        uuid,
        storeId: payBoxStore.id,
        eventId,
        payBoxId,
        discountType: discountTypes.computed.discountTypesEnum().VALUE,
        discount: paymentForm.discount,
        comission,
        offline: false, // TODO hardcode offline
        customer,
        customerId: customer.id,
        items: [...purchasedProducts, ...lendingProducts],
        itemKits,
        invoiceSale: invoiceData || null,
        payments,
        returnSale,
        itemsReturned,
        applicationVersion: appVersions,
        coldOption,
        ...deliveryData,
      }

      if (getters.isConsignedSalePayment) {
        await axiosInstance('/api/sales/pay-box-sale/record-payment', { method: 'PUT', data })
        return
      }

      if (data.id) {
        const delegationSaleReturnOverflow = tempDelegations.find(delegation =>
          delegation.permissions?.some(
            p => p === delegablePermissions.PDV_SALE_RETURN_OVERFLOW_MAX_VALUE.permission
          )
        )
        await axiosInstance('/api/sales/pay-box-sale/finish-order', {
          method: 'PUT',
          data,
          headers: {
            'delegate-token': delegationSaleReturnOverflow?.token,
          },
        })

        const { data: orderPrint } = await axiosInstance.get(`/api/sales/${data.id}`)
        try {
          this.busy = true

          await dispatch('pages/sale/order/printOrderExpedition', { orderPrint }, { root: true })
          await dispatch(
            'pages/pdv/payBoxPrint/printTicketItemsForEvent',
            { orderPrint },
            { root: true }
          )

          let templateToPrint = 'PRINT_INVOICE'

          if (orderPrint?.invoice?.model === 'NFE') {
            // É necessário imprimir como recibo pois foi gerada uma DANFe
            templateToPrint = 'SALE_ORDER'
          }

          // PRINT INVOICE SENDED
          await dispatch(
            'pages/sale/order/printOrder',
            { orderPrint, template: templateToPrint },
            { root: true }
          )

          if (getters.hasRentItems) {
            await dispatch(
              'pages/sale/order/printOrder',
              { orderPrint, template: 'SALE_RENT' },
              { root: true }
            )
          }

          if (getters.hasReturnSaleItems) {
            await dispatch(
              'pages/sale/order/printOrder',
              { orderPrint, template: 'SALE_RETURN' },
              { root: true }
            )
          }
        } catch (err) {
          console.error('print:', err)
        } finally {
          this.busy = false
        }
      } else {
        const { data: saleReturn } = await axiosInstance('/api/sales/pay-box-sale', {
          method: 'POST',
          data,
        })
        if (saleReturn.id > 0) {
          try {
            const { data: orderPrint } = await axiosInstance.get(`/api/sales/${saleReturn?.id}`)

            await dispatch('pages/sale/order/printOrderExpedition', { orderPrint }, { root: true })

            await dispatch(
              'pages/pdv/payBoxPrint/printTicketItemsForEvent',
              { orderPrint },
              { root: true }
            )

            let templateToPrint = 'PRINT_INVOICE'

            if (orderPrint?.invoice?.model === 'NFE' || getters.isChoppGiftCardConversion) {
              // É necessário imprimir como recibo quando for gerada uma DANFe
              templateToPrint = 'SALE_ORDER'
            }

            // PRINT INVOICE SENDED
            await dispatch(
              'pages/sale/order/printOrder',
              {
                orderPrint,
                template: templateToPrint,
              },
              { root: true }
            )

            if (getters.hasLendingItems || getters.hasRentItems) {
              this.busy = true

              if (getters.hasLendingItems) {
                await dispatch(
                  'pages/sale/order/printOrder',
                  { orderPrint, template: 'LENDING_CONTRACT' },
                  { root: true }
                )
              }

              if (getters.hasRentItems) {
                await dispatch(
                  'pages/sale/order/printOrder',
                  { orderPrint, template: 'SALE_RENT' },
                  { root: true }
                )
              }
            }
          } catch (err) {
            console.error('print:', err)
            this.busy = false
          }
        }
      }
    },

    async cancelIncompleteInvoiceSale({ state, dispatch, commit, rootGetters }) {
      await dispatch(
        'pages/pdv/payBoxConfiguration/checkCurrentPayBoxConfigAndFetch',
        {},
        {
          root: true,
        }
      )

      const { uuid, tempDelegations } = state

      const invoiceAgentConfigPayload =
        rootGetters['pages/pdv/payBoxConfiguration/invoiceAgentConfigPayload']

      const { store: payBoxStore, id: payBoxId } =
        rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']

      let detail = {}
      let model = ''
      let serialNumber = ''
      let success = false

      if (isElectron()) {
        if (
          state?.invoiceReturn?.model === 'NFE' &&
          state?.invoiceReturn?.status === 'SUCCESS_FINISHED'
        ) {
          const data = {
            id: state?.invoiceReturn?.id,
            eventType: 'CANCEL_INVOICE',
            justification: 'Cancelando nota devido a erro na finalização da venda',
          }

          const delegationInvoiceSave = tempDelegations.find(delegation =>
            delegation.permissions?.some(
              p => p === delegablePermissions.ERP_INVOICE_MANAGE.permission
            )
          )

          const { data: resultCancel } = await axiosInstance('/api/invoices/invoice-event', {
            method: 'POST',
            data,
            headers: {
              'delegate-token': delegationInvoiceSave?.token,
            },
          })

          if (resultCancel.status === 'SUCCESS_FINISHED') {
            success = true
            model = 'NFE'
            serialNumber = state?.invoiceReturn?.serialNumber

            state.electronicInvoiceInvoiceNumberGenerated = 0

            detail = {
              invoiceNumber: state?.invoiceReturn?.invoiceNumber,
              xmlPath: resultCancel.xml,
              returnMessage: resultCancel.returnMessage,
              returnCode: resultCancel.returnCode,
              accessKey: resultCancel.accessKey,
            }
            commit('SET_INVOICE_RETURN', null)
          }
        } else {
          const uuidPayload = {
            payBoxUuid: uuid,
          }

          const data = {
            ...invoiceAgentConfigPayload,
            request: JSON.stringify(uuidPayload),
          }

          const resultCancel = await window.electronAPI.invoice.cancelIncompleteInvoiceSale({
            ...data,
          })

          if (resultCancel) {
            success = true
            serialNumber = resultCancel.serialNumber
            commit('SET_INVOICE_RETURN', null)

            if (resultCancel.model === 'NFCE') {
              model = 'NFCE'
              detail = {
                xmlPath: resultCancel.xml,
                returnCode: resultCancel.returnCode,
                returnMessage: resultCancel.returnMessage,
                invoiceNumber: resultCancel.invoiceNumber,
                accessKey: resultCancel.accessKey,
              }
            } else if (resultCancel.model === 'SAT') {
              model = 'SAT'
              detail = {
                xmlPath: resultCancel.fileName,
                returnCode: resultCancel.code,
                returnMessage: resultCancel.message,
                invoiceNumber: resultCancel.cfeNumber,
                accessKey: resultCancel.accessKey,
              }
            }
          }
        }

        if (success) {
          const invoiceLog = {
            payBoxId,
            payBoxUuid: uuid,
            storeId: payBoxStore.id,
            model,
            eventType: 'CANCEL_INCOMPLETE_INVOICE_SALE',
            status: 'CANCELED',
            origin: 'PDV',
            serialNumber,
            ...detail,
          }

          await axiosInstance('/api/sales/pay-box/invoice-log', {
            method: 'POST',
            data: invoiceLog,
          })
        }
      }
    },

    async generateInvoice({ commit, state, rootState, rootGetters, dispatch, getters }) {
      const {
        store: payBoxStore,
        id: payBoxId,
        preferredInvoiceType,
      } = rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']

      // Não geramos cupom fiscal para conversão de cartão de presente, pois o cupom foi emitido na venda do cartão
      if (this.isChoppGiftCardConversion) {
        return {}
      }

      const {
        id: saleId,
        uuid,
        items,
        clientForm,
        paymentForm,
        deliveryForm,
        returnForm,
        comission,
        echopeClassification,
        isDelivery,
      } = state
      const allItems = items
        .filter(i => !i.isDeleted)
        .flatMap(i => (i.product.kit ? i.product.kitItems : [i]))
        .filter(i => !i.product.kit)
      try {
        const invoiceItems = allItems
          .filter(
            item =>
              item.classification === 'Sale' ||
              item.classification === 'Credit' ||
              item.product.classification === 'Sale' ||
              item.product.classification === 'Credit'
          )
          .map(i => {
            const unityPrice = i.unitValue || i.product.price
            const unityDiscountValue = payBoxUtils.getDiscountValue({
              discountType: i.discountType || i.product?.discountType,
              discountValue: i.discount || i.product.unitDiscount,
              priceTableDiscount: i.discountPriceTable,
              promotionId: i.promotionId,
              totalValue: unityPrice,
            })

            return {
              productOrigin: i.product.tax.productOrigin,
              skuId: i.product.skuId,
              productName: i.product.name,
              unit: i.product.productUnit,
              cfop: i.product.tax.taxClassification.cfopOutput,
              ncm: i.product.tax.ncmCode,
              cest: i.product.tax.cest?.code || null,
              eanCode: i.product.ean,
              quantity: i.amount,
              unityDiscountValue,
              unityPrice,
              stIcms: i.product.tax.taxClassification.stIcms,
              icmsPercent: i.product.tax.taxClassification.icmsPercent,
              icmsBaseReductionPercent: i.product.tax.taxClassification.icmsBaseReductionPercent,
              stPis: i.product.tax.taxClassification.stPisOutput,
              pisPercent: i.product.tax.taxClassification.pisPercent,
              stCofins: i.product.tax.taxClassification.stCofinsOutput,
              cofinsPercent: i.product.tax.taxClassification.cofinsPercent,
              ibpt: i.product.tax.taxIbpt,
              benefitCode: i.product.tax.taxClassification.benefitCode,
              icmsTaxReliefReason: i.product.tax.taxClassification.icmsTaxReliefReason,
            }
          })

        const invoiceRentItems = allItems
          .filter(item => (item.classification || item.product.classification) === 'Rent')
          .map(i => ({
            productName: i.product.name,
            totalValue: i.priceInfo.priceWithDiscount * i.amount,
          }))

        // no caso do consignado, como pode devolver um valor para o cliente, desconsideramos este valor dos pagamentos efetuados
        let totalReturned =
          returnForm?.returnedItems?.reduce(
            (partialSum, a) => new Decimal(partialSum).add(a.subtotalCalculated).toNumber(),
            0
          ) ?? 0

        const paymentsDto = paymentForm.receipts
          .filter(p => p.canceled === false)
          .map(r => {
            let paymentValueAdjusted = new Decimal(totalReturned || 0).sub(r.value || 0)

            if (paymentValueAdjusted < 0) {
              totalReturned = 0
              paymentValueAdjusted = Math.abs(paymentValueAdjusted)
            } else {
              totalReturned = paymentValueAdjusted
              paymentValueAdjusted = 0
            }
            return {
              paymentType: r.paymentMethod.method,
              paymentMethodId: r.paymentMethod.id,
              paymentValue: paymentValueAdjusted,
              changeValue: r.change,
              paymentMethodInterface: r.paymentMethodInterface,
              nsu: r.nsu,
              cardBrand: r.cardBrand,
            }
          })
          .filter(p => p.paymentValue > 0)

        let customerDto

        const { E3 } = saleDomain.data().echopeClassificationEnum
        if (echopeClassification !== E3 && clientForm.document) {
          customerDto = {
            id: clientForm.id,
            companyName: clientForm.name || null,
            document: clientForm.document,
          }

          if (clientForm.address && getters.clientHasValidAddressToInvoice) {
            customerDto = {
              ...customerDto,
              address: {
                publicPlace: clientForm.address.publicPlace,
                number: clientForm.address.number,
                complement: clientForm.address.complement,
                neighborhood: clientForm.address.neighborhood,
                zipcode: clientForm.address.zipcode,
                city: clientForm.address.city,
                province: clientForm.address.province,
                cityCode: clientForm.address.cityCode,
                provinceCode: clientForm.address.provinceCode,
              },
            }
          }
        }

        let deliveryAddress = null
        let deliveryObservation = null
        if (isDelivery && deliveryForm.address) {
          deliveryAddress = {
            publicPlace: deliveryForm.address.publicPlace,
            number: deliveryForm.address.number,
            complement: deliveryForm.address.complement,
            neighborhood: deliveryForm.address.neighborhood,
            zipcode: deliveryForm.address.zipcode,
            province: deliveryForm.address.province,
            provinceCode: deliveryForm.address.provinceCode,
            city: deliveryForm.address.city,
            cityCode: deliveryForm.address.cityCode,
          }
          deliveryObservation = deliveryForm.observation
        }

        const { payBox } = rootState.pages.pdv.payBoxConfiguration

        const storeKey = `${payBoxStore.id}_${payBox.nfce.serialNumber}`

        const lastStoredInvoiceNumber = await payBoxInvoiceNumberControlIdb.get(storeKey)

        let invoiceNumber = Number(payBox.nfce.lastInvoiceNumber ?? 0) + 1

        if (
          lastStoredInvoiceNumber &&
          Number(lastStoredInvoiceNumber.invoiceNumber ?? 0) + 1 > invoiceNumber
        ) {
          invoiceNumber = Number(lastStoredInvoiceNumber.invoiceNumber ?? 0) + 1
        }

        const invoice = {
          invoiceNumber,
          preferredInvoiceType,
          payBoxUuid: uuid,
          saleId: saleId?.toString(),
          store: payBoxStore,
          customer: customerDto,
          items: invoiceItems,
          itemsRent: invoiceRentItems,
          payments: paymentsDto,
          isDelivery: this.isDelivery,
          deliveryAddress,
          deliveryObservation,
          payBoxId,
          payBoxNumber: payBox.number,
          discountValue: paymentForm.discount + (deliveryForm.deliveryTaxDiscountValue || 0),
          freightValue: deliveryForm.deliveryTax,
          waiterComissionValue: comission,
        }

        const invoiceAgentConfigPayload =
          rootGetters['pages/pdv/payBoxConfiguration/invoiceAgentConfigPayload']

        const data = {
          ...invoiceAgentConfigPayload,
          request: JSON.stringify(invoice),
        }

        const result = await window.electronAPI.invoice.generateAndSubmit({ ...data })

        if (
          result &&
          (result.status === 'SUCCESS_FINISHED' || result.status === 'ISSUED_IN_CONTINGENCY')
        ) {
          commit('SET_INVOICE_RETURN', result)

          await dispatch('blockPayBoxActions', {
            event: payBoxTypes.data().uiPayBoxEventsEnum.SALE_FINISH,
          })

          if (result.model === 'NFCE') {
            await dispatch(
              'pages/pdv/payBoxConfiguration/updateLastInvoiceNumber',
              { invoiceNumber },
              { root: true }
            )
            payBoxInvoiceNumberControlIdb.put({ storekey: storeKey, invoiceNumber })
          }

          let detail = {}

          if (result.model === 'NFCE') {
            detail = {
              xmlPath:
                result.status === 'ISSUED_IN_CONTINGENCY'
                  ? result.nfeDetail.xmlIssuedInContingencyPath
                  : result.nfeDetail.xmlFinishedSuccessPath,
              returnCode: result.nfeDetail.returnCode,
              returnMessage: result.nfeDetail.returnMessage,
              displayMessage: result.displayMessage,
              accessKey: result.nfeDetail.accessKey,
            }
          } else if (result.model === 'SAT') {
            detail = {
              xmlPath: result.satDetail.cfeXmlPath,
              returnCode: result.satDetail.returnCode,
              returnMessage: result.satDetail.returnMessage,
              displayMessage: result.displayMessage,
              accessKey: result.satDetail.cfeAccessKey,
            }
          }

          const invoiceLog = {
            payBoxId: result.payBoxId,
            payBoxUuid: result.payBoxUuid,
            storeId: result.store.id,
            model: result.model,
            invoiceNumber: result.invoiceNumber,
            eventType: 'SUBMIT_INVOICE',
            status: result.status,
            serialNumber: result.serialNumber,
            origin: 'PDV',
            ...detail,
          }

          await axiosInstance('/api/sales/pay-box/invoice-log', {
            method: 'POST',
            data: invoiceLog,
          })

          return detail
        }

        throw new Error(result.message)
      } catch (err) {
        console.error('error generating Customer Invoice', err)

        const getPayboxConfig = async () => {
          dispatch(
            'pages/pdv/payBoxConfiguration/fetchPayBox',
            {},
            {
              root: true,
            }
          ).catch(e => console.error('error on fetchPayBox', e))
        }

        // errors we can fix remote, and must refresh paybox config
        // 206 - Numero de NFe ja invalidado
        if (typeof err === 'string' && ['206'].some(e => err.startsWith(e))) {
          getPayboxConfig()
        }

        // errors we increment the sequence
        // 539 - Duplicidade de NFe com diferenca na Chave de Acesso
        if (typeof err === 'string' && ['539'].some(e => err.startsWith(e))) {
          const { payBox } = rootState.pages.pdv.payBoxConfiguration
          const invoiceNumber = Number(payBox.nfce.lastInvoiceNumber ?? 0) + 1
          await dispatch(
            'pages/pdv/payBoxConfiguration/updateLastInvoiceNumber',
            { invoiceNumber },
            { root: true }
          )
        }
        throw err
      }
    },

    async generateNFe({ commit, state, rootGetters, dispatch }) {
      const { store: payBoxStore } =
        rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']

      // Não geramos cupom fiscal para conversão de cartão de presente, pois o cupom foi emitido na venda do cartão
      if (this.isChoppGiftCardConversion) {
        return {}
      }

      const {
        uuid,
        items,
        clientForm,
        paymentForm,
        deliveryForm,
        returnForm,
        comission,
        tempDelegations,
      } = state
      const allItems = items
        .filter(i => !i.isDeleted)
        .flatMap(i => (i.product.kit ? i.product.kitItems : [i]))
        .filter(i => !i.product.kit)
      try {
        const invoiceItems = allItems
          .filter(
            item =>
              item.classification === 'Sale' ||
              item.classification === 'Credit' ||
              item.product.classification === 'Sale' ||
              item.product.classification === 'Credit'
          )
          .map(i => {
            const unityPrice = i.unitValue || i.product.price
            const unityDiscountValue = payBoxUtils.getDiscountValue({
              discountType: i.discountType || i.product?.discountType,
              discountValue: i.discount || i.product.unitDiscount,
              priceTableDiscount: i.discountPriceTable,
              promotionId: i.promotionId,
              totalValue: unityPrice,
            })
            const totalValue = unityPrice * i.amount

            return {
              productOrigin: i.product.tax.productOrigin,
              skuId: i.product.skuId,
              productName: i.product.name,
              unit: i.product.productUnit,
              cfop: i.product.tax.taxClassification.cfopOutput,
              ncm: i.product.tax.ncmCode,
              cest: i.product.tax.cest?.code || null,
              eanCode: i.product.ean,
              quantity: i.amount,
              unityDiscountValue,
              unityPrice,
              stIcms: i.product.tax.taxClassification.stIcms,
              icmsPercent: i.product.tax.taxClassification.icmsPercent,
              icmsBaseReductionPercent: i.product.tax.taxClassification.icmsBaseReductionPercent,
              stPis: i.product.tax.taxClassification.stPisOutput,
              stIpi: i.product.tax.taxClassification.stIpiOutput,
              pisPercent: i.product.tax.taxClassification.pisPercent,
              stCofins: i.product.tax.taxClassification.stCofinsOutput,
              cofinsPercent: i.product.tax.taxClassification.cofinsPercent,
              ibpt: i.product.tax.taxIbpt,
              benefitCode: i.product.tax.taxClassification.benefitCode,
              icmsTaxReliefReason: i.product.tax.taxClassification.icmsTaxReliefReason,
              productValue: totalValue,
              totalValue,
            }
          })

        const invoiceRentItems = allItems
          .filter(item => (item.classification || item.product.classification) === 'Rent')
          .map(i => ({
            productName: i.product.name,
            totalValue: i.priceInfo.priceWithDiscount * i.amount,
          }))

        // no caso do consignado, como pode devolver um valor para o cliente, desconsideramos este valor dos pagamentos efetuados
        let totalReturned =
          returnForm?.returnedItems?.reduce(
            (partialSum, a) => new Decimal(partialSum).add(a.subtotalCalculated).toNumber(),
            0
          ) ?? 0

        const paymentsDto = paymentForm.receipts
          .filter(p => p.canceled === false)
          .map(r => {
            let paymentValueAdjusted = new Decimal(totalReturned || 0).sub(r.value || 0)

            if (paymentValueAdjusted < 0) {
              totalReturned = 0
              paymentValueAdjusted = Math.abs(paymentValueAdjusted)
            } else {
              totalReturned = paymentValueAdjusted
              paymentValueAdjusted = 0
            }
            return {
              paymentType: r.paymentMethod.method,
              paymentMethodId: r.paymentMethod.id,
              paymentValue: paymentValueAdjusted,
              changeValue: r.change,
              paymentMethodInterface: r.paymentMethodInterface,
              nsu: r.nsu,
              cardBrand: r.cardBrand,
            }
          })
          .filter(p => p.paymentValue > 0)

        let totalOtherCostsRent = 0
        let itensRentName = ''

        invoiceRentItems.forEach(rent => {
          totalOtherCostsRent += rent.totalValue
          itensRentName += `${rent.productName}, `
        })

        let additionalInformation = ''

        if (totalOtherCostsRent != null && totalOtherCostsRent > 0 && itensRentName !== '') {
          const stringItemsRent = `Foi adicionado um valor de ${totalOtherCostsRent} como acrescimo na venda referente ao aluguel dos itens: ${itensRentName.slice(
            0,
            -2
          )};`
          additionalInformation += stringItemsRent
        }

        if (comission != null && comission > 0) {
          const stringComission = `Foi adicionado um valor de ${comission} como acrescimo na venda referente a gorjeta do garçom;`
          additionalInformation += stringComission
        }

        const totalOtherCosts = totalOtherCostsRent + comission

        const bodyItems = {
          items: invoiceItems,
          discount: paymentForm.discount + (deliveryForm.deliveryTaxDiscountValue || 0),
          freigth: deliveryForm.deliveryTax,
          otherCosts: totalOtherCosts,
          insurance: 0,
          purposeType: 'NORMAL',
        }

        const delegationInvoiceSave = tempDelegations.find(delegation =>
          delegation.permissions?.some(
            p => p === delegablePermissions.ERP_INVOICE_GENERATE.permission
          )
        )

        const { data: itemsWithTax } = await axiosInstance('/api/invoices/calculate-item-tax', {
          method: 'POST',
          data: bodyItems,
          headers: {
            'delegate-token': delegationInvoiceSave?.token,
          },
        })

        const totalProductsValue = itemsWithTax.reduce(
          (total, item) => total + item.productValue,
          0
        )
        const totalValue = itemsWithTax.reduce((total, item) => total + item.totalValue, 0)
        const cfopCode = itemsWithTax[0].cfop

        const invoice = {
          invoiceNumber: state?.electronicInvoiceInvoiceNumberGenerated ?? 0,
          model: 'NFE',
          storeId: payBoxStore.id,
          serialNumber: payBoxStore.invoiceSerialNumber.toString(),
          customerId: clientForm.id,
          issueDate: moment().format(),
          movimentDate: moment().format(),
          movementType: 'OUTPUT',
          operationType: 'INTERNAL',
          invoiceCategory: 'SaleFromPdv',
          productsValue: totalProductsValue,
          freightValue: deliveryForm.deliveryTax,
          insuranceValue: 0,
          otherCostsValue: totalOtherCosts,
          discountValue: paymentForm.discount + (deliveryForm.deliveryTaxDiscountValue || 0),
          totalValue,
          items: itemsWithTax,
          payments: paymentsDto,
          purposeType: 'NORMAL',
          isFinalConsumer: true,
          presenceIndicator: 'PRESENTIAL_OPERATION',
          natureOperation: 'Venda de Mercadoria',
          paymentIndicator: 'OTHER',
          additionalInformation,
          cfopCode,
          saleUuid: uuid,
        }

        const { data: invoiceCreated } = await axiosInstance('/api/invoices', {
          method: 'POST',
          data: invoice,
          headers: {
            'delegate-token': delegationInvoiceSave?.token,
          },
        })

        let detail = {}

        if (!invoiceCreated) {
          console.error('Error on generating nfe', 'Ocorreu um erro ao emitir a nota fiscal!')
          throw new Error('Ocorreu um erro ao emitir a nota fiscal!')
        }
        try {
          this.loading = true
          state.electronicInvoiceInvoiceNumberGenerated = invoiceCreated.invoiceNumber

          const payload = {
            invoiceRequestType: 'GENERATE_AND_SEND_INVOICE',
            id: invoiceCreated.id,
          }

          const { data: invoiceResponse } = await axiosInstance('/api/invoices/invoice-manage', {
            method: 'POST',
            data: payload,
            headers: {
              'delegate-token': delegationInvoiceSave?.token,
            },
          })

          if (invoiceResponse && invoiceResponse.status === 'SUCCESS_FINISHED') {
            commit('SET_INVOICE_RETURN', invoiceResponse)
            await dispatch('blockPayBoxActions', {
              event: payBoxTypes.data().uiPayBoxEventsEnum.SALE_FINISH,
            })

            detail = {
              xmlPath: invoiceResponse.nfeDetail.xmlFinishedSuccessPath,
              returnMessage: invoiceResponse.nfeDetail.returnMessage,
              displayMessage: invoiceResponse.displayMessage,
              accessKey: invoiceResponse.nfeDetail.accessKey,
            }
          } else if (invoiceResponse.status === 'WAITING_FOR_RESPONSE') {
            console.error(
              'Nota fiscal pendente de resposta da Sefaz. Aguarde alguns segundos e tente finalizar novamente para atualizar o status!'
            )
            throw new Error(
              'Nota fiscal pendente de resposta da Sefaz. Aguarde alguns segundos e tente finalizar novamente para atualizar o status!'
            )
          } else {
            console.error('error on generate nfe', invoiceResponse.nfeDetail.returnMessage)
            throw new Error(invoiceResponse.nfeDetail.returnMessage)
          }
        } catch (error) {
          console.error(error)
          throw error
        } finally {
          this.loading = false
        }
        return detail
      } catch (err) {
        console.error('error generating Invoice(NFe)', err)
        throw err
      }
    },

    async upsertIdbSession({ state, dispatch }) {
      try {
        const { uuid } = state

        if (!uuid && (await dispatch('checkHasLastSession'))) {
          await dispatch('restoreIdbLastSession')
          return
        }
        const generateNewUuid = () => {
          const uuidSize = 20 // estamos usando 20 caracteres por no TEF ter esse limite no ID da venda
          let tries = 0 // evicting infinite loop
          do {
            const newUuid = nanoid(uuidSize)
            const lastUuid = localStorage.getItem('last_uuid_sale')
            if (!lastUuid || lastUuid !== newUuid) {
              localStorage.setItem('last_uuid_sale', newUuid)
              return newUuid
            }
            tries += 1
          } while (tries <= 20)

          return nanoid(uuidSize)
        }

        const payload = JSON.stringify(state)
        const uuidGen = state.uuid ?? generateNewUuid()
        await payBoxCurrentSaleIdb.put({ uuid: uuidGen, payload })
        state.uuid = uuidGen
      } catch (err) {
        console.error('store paybox save in indexeddb', err)
        throw err
      }
    },
    async checkHasLastSession() {
      try {
        const payBoxStorage = await payBoxCurrentSaleIdb.toArray()

        if (payBoxStorage.length > 0) return true
        return false
      } catch (err) {
        console.error('store paybox restore from indexeddb', err)
        throw err
      }
    },
    async restoreIdbLastSession({ commit }) {
      try {
        const payBoxStorage = await payBoxCurrentSaleIdb.limit(1).toArray()
        const data = payBoxStorage[0]
        if (data) {
          commit('SET_ALL_STATE', { ...JSON.parse(data.payload), uuid: data.uuid })
        }
      } catch (err) {
        console.error('store paybox restore from indexeddb', err)
        throw err
      }
    },
    async finishIdbSession({ state }) {
      try {
        if (state.uuid) {
          await payBoxCurrentSaleIdb.delete(state.uuid)
          state.uuid = null
          state.paymentStarted = false
        }
      } catch (err) {
        console.error('store paybox finish in indexeddb', err)
        throw err
      }
    },

    AddTempDelegation({ commit }, delegation) {
      commit('ADD_TEMP_DELEGATIONS', delegation || {})
    },

    blockPayBoxActions({ commit, state }, { event }) {
      const {
        PAYMENT_ADD,
        PAYMENT_REMOVE,
        PRODUCT_ADD,
        PRODUCT_REMOVE,
        PRODUCT_UPDATE,
        SALE_ADD_DISCOUNT,
        SALE_UPDATE_DISCOUNT,
      } = payBoxTypes.data().uiPayBoxActionsEnum
      const { SALE_FINISH } = payBoxTypes.data().uiPayBoxEventsEnum
      let blockedList = [...state.blockedPayBoxActions]

      if (event === SALE_FINISH) {
        blockedList = [
          ...blockedList,
          PAYMENT_ADD,
          PAYMENT_REMOVE,
          PRODUCT_ADD,
          PRODUCT_REMOVE,
          PRODUCT_UPDATE,
          SALE_ADD_DISCOUNT,
          SALE_UPDATE_DISCOUNT,
        ]
      }

      const blockedListUniques = [...new Set(blockedList)]
      commit('SET_BLOCKED_PAY_BOX_ACTIONS', { actions: blockedListUniques })
    },
    unblockPayBoxActions({ commit }) {
      commit('SET_BLOCKED_PAY_BOX_ACTIONS', { actions: [] })
    },
    validatePayBoxActionIsBlocked({ state }, { action }) {
      if (state.blockedPayBoxActions.length === 0) return false
      return state.blockedPayBoxActions.some(blocked => blocked === action)
    },

    cleanItemFormMeta({ commit }) {
      commit('CLEAN_ITEM_FORM_META')
    },
    cleanItemFormProduct({ commit }) {
      commit('CLEAN_ITEM_FORM_PRODUCT')
    },
    async cleanClientForm({ commit }) {
      commit('CLEAN_CLIENT_FORM')
      // dispatch('pages/pdv/saleProducts/cleanProducts', null, { root: true })
    },
    async cleanPalmBeerData({ commit }) {
      commit('CLEAN_PALM_BEER_DATA')
    },
    async cleanChoppGiftCardData({ commit }) {
      commit('CLEAN_CHOPP_GIFT_CARD_DATA')
    },
    async cleanClientFormWithoutDocument({ commit }) {
      commit('CLEAN_CLIENT_FORM_WITHOUT_DOCUMENT')
      // dispatch('pages/pdv/saleProducts/cleanProducts', null, { root: true })
    },
    async cleanState({ commit, dispatch }) {
      await dispatch('finishIdbSession')
      commit('CLEAN_STATE')
    },
    async cancelIncompleteInvoice({ dispatch }) {
      await dispatch('cancelIncompleteInvoiceSale')
    },
  },
}
